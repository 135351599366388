<template>
  <svg
    width="65"
    height="78"
    viewBox="0 0 65 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3555 0.546875V77.3748"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.84375 45.9609L32.3528 77.3724L63.8618 45.9609"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
